<template>
  <v-layout wrap>
    <v-flex xs8 style="padding: 10px;">
      <list-card
        v-if="selectedViewType === 'card'"
        :devices="devices"
        :viewTypes="viewTypes"
        :onChangeView="(v) => { selectedViewType = v }"
        :onEdit="(v) => { editedID = v }"
        :onRefresh="() => { handleGetData(null) }"
        :locale="locale"
      />
      <list-row
        v-if="selectedViewType === 'row'"
        :devices="devices"
        :viewTypes="viewTypes"
        :onChangeView="(v) => { selectedViewType = v }"
        :onEdit="(v) => { editedID = v }"
        :onSearch="handleSearch"
        :onRefresh="() => { handleGetData(null) }"
        :locale="locale"
        :searchValue="searchValue"
      />
    <pagination
      v-if="count!= null"
      :startIndex="startIndex"
      :count="count"
      :itemsPerPage="itemsPerPage"
      :onGetData="handleGetData"
      :locale="locale"
      :onChangeItemsPerPage="handleChangeItemsPerPage"
      :arrayPages="[5, 10, 15, 20, 25, 30, 50]"
    />
    </v-flex>
    <v-flex xs4 style="padding: 10px;">
      <!--v-btn 
        v-if="!editedID"
        @click="handleAddItem"
        block
        color="primary"
      >
        a�adir
      </v-btn-->
      <div
        style="padding: 10px;background-color:white;min-height: calc(100vh);"
      >
        <totem-form
          v-if="editedID && workspaceID"
          :workspaceID="workspaceID"
          :editedID="editedID"
          :onClose="handleClose"
          :allowedTasks="allowedTasks"
        />
      </div>
    </v-flex>
  </v-layout>
</template>
<script>
import { mapState } from 'vuex'
import api from '@/services/api'
import utilsService from '@/services/utils'
//import Search from '@/components/Search'
import Pagination from '@/components/customTable/Pagination'
import TotemForm from './TotemForm'
import ListCard from './ListCard'
import ListRow from './ListRow'
export default {
  components: {
    //Search,
    Pagination,
    TotemForm,
    ListCard,
    ListRow,
  },
  data: () => ({
    count: null,
    startIndex: 0,
    itemsPerPage: 15,
    presentations: [],
    devices: [],
    editedID: null,
    editedPresentationID: null,
    searchValue: '',
    templates: [],
    allowEditPresentation: false,
    selectedViewType: 'row',
    allowedTasks: [],
  }),
  computed: {
    ...mapState('app',['locale', 'playersWorkspace']),
    workspaceID () {
      return this.playersWorkspace
    },  
    environment () {
      return 'players'
    },
    headers () {
      return [
        {
          text: 'Name',
          align: 'start',
          sortable: false,
          value: 'ident',
        },
        { text: 'Cliente', sortable: false, value: 'WorkspaceName' },
        { text: 'Versi�n', sortable: false, value: 'Version' },
        { text: 'URL', sortable: false, value: 'URL' },
        /*
        { text: 'Presentation', align: 'center', sortable: false, value: 'Presentation' },
        { text: 'Time(seg)', sortable: false, value: 'Time' },
        { text: 'APK', align: 'center', sortable: false, value: 'APK' },
        */
        { text: 'LCD', sortable: false, value: 'LCD' },
        { text: 'LCD', sortable: false, value: 'Seconds' },
      ]
    },
    viewTypes () {
      return [
        {
          id: 'row',
          name: 'Lista',
        },
        {
          id: 'card',
          name: 'Tarjetas'
        },
      ]
    },
  },
  watch: {
    playersWorkspace () {
      //this.handleGetPresentations()
      this.handleGetData()
    },
  },
  mounted () {
    this.handleGetAllowedTask()
    //this.handleGetTemplates()
    const aux = localStorage.getItem('playerItemsPerPage')
    if (aux && aux !== this.itemsPerPage) this.itemsPerPage = aux && aux.length > 0 ? aux | 0 : 15
    //this.handleGetData()
    //this.handleGetPresentations()
    this.handleGetData()
  },
  methods: {
    handleGetAllowedTask () {
      this.allowedTasks = []
      const url = `v1/internal/users/${utilsService.getUser()}/totem-tasks`
      api.getAllWithoutLimit (this.environment, url)
        .then(response => {
          this.allowedTasks = response.allowedTask ? response.allowedTask : null
          console.log(this.allowedTasks)
        })
    },
    handleGetTemplates () {
      const url = 'v1/private/templates'
      api.getAllWithoutLimit (this.environment, url)
        .then(response => {
          this.templates = response
        })
    },
    getDate (v) {
      const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }

      return v.toLocaleDateString('es', options)
    },
    handleGetPresentations () {
      this.presentations = []
      this.count = 0
      this.devices = []
      if (!this.workspaceID) return
      
      //const url = 'v1/private/presentations/'
      const url = `v1/private/workspaces/${this.workspaceID}/presentations/`
      api.getAllWithoutLimit (this.environment, url)
        .then(response => {
          this.presentations = response.data
          this.handleGetData()
        })
    },
    handleChangeItemsPerPage (v) {
      this.startIndex = 0
      this.itemsPerPage = v
      localStorage.setItem('playerItemsPerPage', v)
      this.handleGetData()
    },
    handleSearch (v) {
      this.searchValue = v
      this.handleGetData()
    },
    async handleGetData (v) {
      this.handleClose()
      this.count = 0
      this.devices = []
      if (!this.workspaceID) return
      if (v !== null && v != undefined) this.startIndex = v

      //const url = 'v1/private/android/'
      const url = `v1/private/workspaces/${this.workspaceID}/totems/`
      api.getAll (this.environment, url, this.startIndex, this.itemsPerPage, this.searchValue)
        .then(response => {
          this.count = response.count
          this.devices = response.data.map(item => {
            item.ident = {
              ID: item.ID,
              Name: item.Name,
            }
            //item.Presentation = this.presentations.filter(x => x.ID ===item.PresentationID).map(item=>item.Name).shift()
            item.LCD = item.LCD ? new Date(item.LCD) : null
            return item
          })
        })
    },
    handleAddItem () {
      this.editedID = 'new'
    },
    handleClose (refresh) {
      this.editedID = null
      this.editedPresentationID = null
      if (refresh) this.handleGetData()
    },
    handleClosePresentationForm (refresh) {
      this.editedID = null
      this.editedPresentationID = null
      if (refresh) this.handleGetPresentations()
    },
    handleGoToActivate () {
        this.$router.push('/players/activate')
    },
    handleEditPresentationByID (v) {
      if (!this.allowEditPresentation) return
      this.editedPresentationID = v
    },
  },
}
</script>

